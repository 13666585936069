import { mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { Division, IDualMeetBout } from "../../types";
import { boutFencerName } from "../../utils/helpers";
import useDivision from "../../hooks/divison";

import "./LiveNowBout.css";

const LiveNowBout = ({ data, onClick }: { data: IDualMeetBout; onClick: () => void }) => {
    const division = useDivision();
    
    return (
        <div className={division === Division.NCAA ? "liveNowBoutCollege" : "liveNowBout"} onClick={onClick}>
            <h4 style={{ fontWeight: 500 }}>
                <Icon path={mdiEye} size={1} color="#F00" /> Live now - {data.weapon} Round #{Math.floor(data.order / 3) + 1} Bout #
                {(data.order % 3) + 1}
            </h4>
            <h6>
                {boutFencerName(data.fencer1)} ({data.fencer1.strip || "Sub"}) vs{" "}
                {boutFencerName(data.fencer2)} ({data.fencer2.strip || "Sub"})
            </h6>
            <h6>
                Score: {data.fencer1.score}-{data.fencer2.score}
            </h6>
        </div>
    );
}

export default LiveNowBout;