import { Box } from "@mui/material";
import { Weapon, IDualMeetBout, BoutSide, Division } from "../../types";
import { boutFencerName, boutWinner } from "../../utils/helpers";
import useDivision from "../../hooks/divison";
import LocalSettings from "../../utils/localSettings";

const homeStrips = {
    A: 1,
    B: 2,
    C: 3,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
};

const awayStrips = {
    A: 4,
    B: 5,
    C: 6,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
};

interface BoutsListProps {
    bouts: Record<Weapon, IDualMeetBout[]>;
    boutsRaw: IDualMeetBout[];
    runningScores: ([number, number] | null)[];
    weapon: Weapon | "All";
    setCurrentBoutID: React.Dispatch<React.SetStateAction<string | null>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tabsRef?: React.MutableRefObject<any>;
}

const BoutsList = ({ bouts, boutsRaw, runningScores, weapon, setCurrentBoutID, tabsRef }: BoutsListProps) => {
    const division = useDivision();
    const colorByWinner = LocalSettings.getColorByWinner();

    const bgOptions = {
        Sabre: "rgba(30, 89, 144, 0.5)",
        Foil: "rgba(190, 150, 27, 0.5)",
        Epee: "rgba(186, 186, 186, 0.5)"
    };

    const boutsOfWeapon = weapon === "All" ? boutsRaw : Object.values(bouts[weapon] ?? {});
    
    return (
        <Box>
            {boutsOfWeapon.map((bout, idx) => {
                const winnerLeft = boutWinner(bout) === BoutSide.Fencer1;
                const winnerRight = boutWinner(bout) === BoutSide.Fencer2;

                const leftScoreColor = colorByWinner
                    ? winnerLeft ? "green" : winnerRight ? "red" : "gray"
                    : "red";
                const rightScoreColor = colorByWinner
                    ? winnerRight ? "green" : winnerLeft ? "red" : "gray"
                    : "green";

                return (
                    <div
                        key={`boutOfWeapon${bout.id}`}
                        style={division === Division.NJSIAA ? { backgroundColor: bgOptions[bout.weapon] } : {}}
                    >
                        {idx % 3 === 0 ? (
                            <div
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                                    color: "#FFF",
                                    fontSize: "1em",
                                    padding: "6px 4px 4px 4px"
                                }}
                            >
                                {weapon === "All" ? bout.weapon : ""} Round {Math.floor(bout.order / 3) + 1}
                            </div>
                        ) : null}
                        <div
                            key={`asdfasdffmk${idx}`}
                            className="boutInViewerList"
                            onClick={() => {
                                setCurrentBoutID(bout.id);
                                tabsRef?.current?.switchTab(2);
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    left: 10,
                                    fontSize: 48,
                                    width: 40,
                                    textAlign: "right"
                                }}
                            >
                                {homeStrips[bout.fencer1.strip]}
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    right: 10,
                                    fontSize: 48,
                                    width: 40,
                                    textAlign: "left"
                                }}
                            >
                                {awayStrips[bout.fencer2.strip]}
                            </div>
                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <div style={{ marginBottom: "10px" }}>
                                    {boutFencerName(bout.fencer1)}
                                </div>
                                <div className="scoreBox" style={{ backgroundColor: leftScoreColor }}>
                                    {bout.fencer1.score}
                                </div>
                            </div>
                            <div
                                style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >
                                <div style={{ marginBottom: "10px" }}>
                                    {boutFencerName(bout.fencer2)}
                                </div>
                                <div className="scoreBox" style={{ backgroundColor: rightScoreColor }}>
                                    {bout.fencer2.score}
                                </div>
                            </div>
                            {runningScores[idx] && (
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        left: 0,
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: 18
                                    }}
                                >
                                    {runningScores[idx]![0]} - {runningScores[idx]![1]}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </Box>
    );
};

export default BoutsList;