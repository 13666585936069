import { mdiSquare, mdiSquareOutline } from "@mdi/js";
import { Box } from "@mui/material";
import { BoutSide, IDualMeetBout, IDualMeetBoutFencer } from "../../types";
import { MedicalIcon } from "../MedicalIcon";
import Icon from "@mdi/react";
import "./BoutFencerSection.css";
import { boutFencerName, boutWinner } from "../../utils/helpers";

interface BoutFencerSectionProps {
    side: "left" | "right";
    colorBasedOnWinner?: boolean;
    mobile: boolean;
    bout: IDualMeetBout;
    fencer: IDualMeetBoutFencer;
    setMedicalInfoOpen?: (v: boolean) => void;
    setMedicalInfoSide?: (v: "left" | "right") => void;
}

const BoutFencerSection = ({ bout, fencer, mobile, side, colorBasedOnWinner, setMedicalInfoOpen, setMedicalInfoSide }: BoutFencerSectionProps) => {
    const medicalIcon = fencer.medicalForfeit || fencer.medicalFencerInfo;

    const openMedicalInfo = setMedicalInfoOpen || ((_: boolean) => {});
    const sideMedicalInfo = setMedicalInfoSide || ((_: "left" | "right") => {});

    const winner = boutWinner(bout) === (side === "left" ? BoutSide.Fencer1 : BoutSide.Fencer2);
    const noWinner = boutWinner(bout) === null;

    const backgroundColor = colorBasedOnWinner
        ? noWinner ? "hsl(0 0% 65%)" : winner ? "hsl(143 79% 72%)" : "hsl(0 79% 72%)"
        : side === "left" ? "hsl(0 79% 72%)" : "hsl(143 79% 72%)";
    const borderColor = colorBasedOnWinner
        ? noWinner ? "hsl(0 0% 70%)" : winner ? "hsl(143 79% 50%)" : "hsl(0 79% 50%)"
        : side === "left" ? "hsl(0 79% 50%)" : "hsl(143 79% 50%)";

    const containerStyle = mobile ? { width: "50%", height: "250px" } : { width: "33%", height: "100%", flexShrink: 0 };
    
    return (
        <div style={containerStyle}>
            <div className="boutPlayer">
                <div
                    style={{
                        marginBottom: 5,
                        height: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <h3
                        style={{
                            margin: 0,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: 18,
                            fontWeight: 500
                        }}
                    >
                        {boutFencerName(fencer)}
                    </h3>
                    {medicalIcon ? (
                        <MedicalIcon side={side} setMedicalInfoOpen={openMedicalInfo} setMedicalInfoSide={sideMedicalInfo} />
                    ) : null}
                </div>
                <h5
                    style={{
                        marginBottom: 5,
                        height: 20,
                        fontSize: 14
                    }}
                >
                    {fencer.fencerInfo.teamAbbreviation}
                </h5>
                <div className="currentBoutScore" style={{ backgroundColor, borderColor }}>
                    {fencer.score}
                    {bout.priority === side ? <div className="currentBoutScorePriority">PRIORITY</div> : null}
                    {fencer.forfeit ? (
                        <Box className="currentBoutScorePriority">
                            FORFEIT
                            {fencer.medicalForfeit && (
                                <div style={{ marginBottom: 2 }}>
                                    <MedicalIcon side={side} setMedicalInfoOpen={openMedicalInfo} setMedicalInfoSide={sideMedicalInfo} />
                                </div>
                            )}
                        </Box>
                    ) : null}
                </div>
                <div
                    style={{
                        backgroundColor: "#999",
                        borderRadius: 8,
                        marginTop: 10,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}
                >
                    <Icon path={fencer.cardInfo.yellow ? mdiSquare : mdiSquareOutline} size="36px" color="yellow" />
                    <div
                        style={{
                            position: "relative"
                        }}
                    >
                        {fencer.cardInfo.red > 0 && (
                            <span
                                style={{
                                    fontSize: 20,
                                    position: "absolute",
                                    left: 0,
                                    top: 3,
                                    textAlign: "center",
                                    width: "100%"
                                }}
                            >
                                {fencer.cardInfo.red}
                            </span>
                        )}
                        <Icon path={fencer.cardInfo.red ? mdiSquare : mdiSquareOutline} size="36px" color="red" />
                    </div>
                    <Icon path={fencer.cardInfo.black ? mdiSquare : mdiSquareOutline} size="36px" color="black" />
                </div>
            </div>
        </div>
    );
};

export default BoutFencerSection;
