class LocalSettings {
    /**
     * If true, red/green is always left/right in bouts list, otherwise red/green is loser/winner
     */
    static getColorByWinner(): boolean {
        return localStorage.getItem(`/settings/colorByWinner`) === "true";
    }

    /**
     * If true, red/green is always left/right in bouts list, otherwise red/green is loser/winner
     */
    static setColorByWinner(value: boolean) {
        localStorage.setItem(`/settings/colorByWinner`, JSON.stringify(value));
    }
}

export default LocalSettings;