import { Dialog, DialogTitle, DialogContent, FormControlLabel, Switch, Typography, DialogActions, Button } from "@mui/material"
import LocalSettings from "../../utils/localSettings";

const BoutViewerSettingsDialog = ({ visible, close }: { visible: boolean; close: () => void }) => {
    const fixedRedGreen = !LocalSettings.getColorByWinner();

    return <Dialog open={visible} onClose={close}>
        <DialogTitle>Bout Viewer Settings</DialogTitle>
        <DialogContent>
            <FormControlLabel
                control={<Switch defaultChecked={fixedRedGreen} onChange={e => LocalSettings.setColorByWinner(!e.target.checked)} />}
                label="Fixed red/green position?"
            />
            <br />
            <Typography variant="subtitle1" sx={{ fontSize: "14px", marginTop: -1.5 }}>
                { fixedRedGreen
                    ? "In the bouts list, the left side will always be red and the right side will always be green."
                    : "In the bouts list, the winning side will be green and the losing side will be red."
                }
            </Typography>
        </DialogContent>
        <DialogActions sx={{ marginRight: "10px", marginBottom: "10px" }}>
            <Button variant="text" onClick={close}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default BoutViewerSettingsDialog;